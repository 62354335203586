import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-i-learn',
  templateUrl: './i-learn.component.html',
  styleUrls: ['./i-learn.component.scss']
})
export class ILearnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
