import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  supportedLanguages = ['nl', 'en'];

  constructor() {
  }

  public getSupportedLanguages(): string[] {
    return this.supportedLanguages;
  }
}
