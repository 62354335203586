import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  withPricing = true;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const extraSection = this.route.snapshot.paramMap.get('extraSection');
    if (extraSection === 'withTempPricingEtc') {
      this.withPricing = true;
    }
  }

}
