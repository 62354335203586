import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  currentLang: string;
  supportedLanguages = this.translationsService.getSupportedLanguages();

  constructor(public translate: TranslateService, public translationsService: TranslationsService) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
  }

  public switchToLanguage(languageCode: string){
    this.translate.use(languageCode);
    this.currentLang = languageCode;
  }

}
